import React, { Component } from 'react'
import { openAR } from '../../helpers/ar';

class Example extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.ref = React.createRef();
    }

    onClick() {
        if (this.props.modelName) openAR(this.props.modelName);
    }

    render() {
        this.inViewport = this.ref.current !== null && this.ref.current.getBoundingClientRect().top < window.innerHeight && this.ref.current.getBoundingClientRect().bottom > 0;
        return (
            <div className="example" ref={this.ref} onClick={() => { if (this.props.image) this.onClick() }}>
                {this.inViewport && (<div className="image" style={{ backgroundImage: "url(" + this.props.image + ")" }}>
                    {this.props.vectaryLink && <iframe src={this.props.vectaryLink} frameBorder="0" width="100%" height="100%" />}
                </div>)}
                {!this.inViewport && <div className="image" />}
                <div className="button" onClick={this.onClick}>Explore in AR</div>
            </div>
        )
    }
}

export default Example;