import React, { Component } from 'react'

class CategoryHeadline extends Component {

    render() {
        return (
            <div className="categoryHeadline">
                <div className="line"/>
                <div className="text">{this.props.text}</div>
            </div>
        )
    }
}

export default CategoryHeadline;