import React, { Component } from 'react'
import { openAR } from '../../helpers/ar';

class FancyLink extends Component {

    onClick() {
        window.open(this.props.link, "_blank");
    }

    render() {
        return (
            <div className="fancyLink" onClick={this.onClick.bind(this)}>
                <div className="image" style={{ backgroundImage: 'url(' + this.props.image + ')' }} />
                <div className="button">{this.props.buttonText}</div>
            </div>
        )
    }
}

export default FancyLink;