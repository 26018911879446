import React, { Component } from 'react'
import { connect } from 'react-redux'

//Components
import CategoryHeadline from '../../components/CategoryHeadline';
import Example from '../../components/Example';
import FancyLink from '../../components/FancyLink';

//Example Images
import HorseImg from '../../../images/horse.png';

//FancyLink Images
import NiviImg from '../../../images/nivi.png';

//AR Items
import SofaImg from '../../../images/sofa.png';
import ChairImg from '../../../images/chair.png';
import WanduhrImg from '../../../images/wanduhr.png';
import PictureFrameImg from '../../../images/pictureframe.png';
import FloorlampImg from '../../../images/floorlamp.png';
import NikeShoeImg from '../../../images/nikeshoe.png';
import FashionWomenImg from '../../../images/fashionwomen.png';
import BballplayerImg from '../../../images/bballplayer.png';
import FitnessWomenImg from '../../../images/fitnesswomen.png';
import RingImg from '../../../images/ring.png';
import WatchImg from '../../../images/watch.png';
import GameBoyImg from '../../../images/gameboy.png';
import iMacImg from '../../../images/imac.png';
import FridgeImg from '../../../images/fridge.png';
import ArcadeImg from '../../../images/arcade.png';
import TvImg from '../../../images/tv.png';



@connect((store) => {
    return {
        example: store.example,
    }
})

class PageHome extends Component {

    constructor(props) {
        super(props);
        this.state = { scroll: 0 }
        this.contentRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        document.title = "Demodern AR Examples";
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        this.setState({ scroll: window.pageYOffset });
    }

    render() {
        return (
            <div className="content" ref={this.contentRef}>
                <p>We develop custom augmented reality (AR) solutions. Whether furnishing, product design, automotive, gaming or trade fairs - we design and develop tailor-made AR mobile apps and coming WebAR applications for every sector.</p>
                <CategoryHeadline text={"Furnishing"} />
                {/* Sofa */}
                <Example modelName={"Sofa"} image={SofaImg} />
                {/* Chair */}
                <Example modelName={"Chair"} image={ChairImg}  />
                {/* Wanduhr */}
                <Example modelName={"Wanduhr"} image={WanduhrImg} />
                {/* PictureFrame */}
                <Example modelName={"PictureFrame"} image={PictureFrameImg} />
                {/* Floorlamp */}
                <Example modelName={"Floorlamp"} image={FloorlampImg} />

                
                <CategoryHeadline text={"Electronics"} />
                
                {/* TV */}
                <Example modelName={"TV"} image={TvImg} />
                {/* iMac */}
                <Example modelName={"iMac"} image={iMacImg} />
                {/* Fridge */}
                <Example modelName={"Fridge"} image={FridgeImg} />
                {/* GameBoy */}
                <Example modelName={"GameBoy"} image={GameBoyImg} />
                {/* Arcade */}
                <Example modelName={"Arcade"} image={ArcadeImg} />
                
                <CategoryHeadline text={"Fashion"} />
                {/* NikeShoe (NikeShoeThree is a new model saved through blender, replacing the old file somehow isn't working)*/}
                <Example modelName={"NikeShoeThree"} image={NikeShoeImg} />
                {/* FashionWomen */}
                <Example modelName={"FashionWomen"} image={FashionWomenImg} />
                {/* BBallPlayer */}
                <Example modelName={"BBallPlayer"} image={BballplayerImg} />
                {/* FitnessWomen */}
                <Example modelName={"FitnessWomen"} image={FitnessWomenImg} />
                {/* Watch */}
                <Example modelName={"Watch"} image={WatchImg} />
                {/* Ring */}
                <Example modelName={"Ring"} image={RingImg} />

                <CategoryHeadline text={"Experience"} />
                <FancyLink buttonText={"Go To App Store"} link={"https://demodern.de/projekte/nivea-nivis-world"} image={NiviImg} backgroundColor={'#3b45c7'} />

                <CategoryHeadline text={"Contact"} />
                <h2>Want to find out more?</h2>
                <a className='button' href={'mailto:hi@demodern.de'}>Drop us a message</a>
                <a className='button' href={'https://demodern.de/'} target='_blank'>Go to our homepage</a>
            </div>
        )
    }
}

export default PageHome;