
const IS_ANDROID = /android/i.test(navigator.userAgent);
const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !self.MSStream;
let host = "ar-dmdr-io.s3.amazonaws.com"; //ar.dmdr.io
let folder = "/dist/models/"

const openIOSARQuickLook = ((path) => {
    const anchor = document.createElement("a");
    anchor.setAttribute("rel", "ar");
    anchor.setAttribute("href", path);
    anchor.appendChild(document.createElement('img'));
    anchor.click();
});

const openAndroidQuickLook = ((path) => {
    const anchor = document.createElement("a");
    const arPath = `intent://arvr.google.com/scene-viewer/1.0?file=${path}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
    console.log("AR Path: " + arPath);
    anchor.setAttribute("href", arPath);
    anchor.click();
});

export function openAR(modelName) {
    const AndroidPath = 'https://' + host + folder + modelName + "/" + modelName + ".glb";
    const iOsPath = folder + modelName + "/" + modelName + ".usdz"; //.gltf.usdz
    console.log("Android: " + AndroidPath);
    console.log("iOs: " + iOsPath);
    if (IS_ANDROID) {
        openAndroidQuickLook(AndroidPath);
    }
    else if (IS_IOS) {
        openIOSARQuickLook(iOsPath);
    } else {
        // PC try all         
        openAndroidQuickLook(AndroidPath);
        openIOSARQuickLook(iOsPath);
    }
};

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}