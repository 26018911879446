import React, { Component } from 'react'
import HeaderVideo from './../../../videos/header.mp4';
import logo from './../../../images/dmdrn_patchlogo.png';

class Header extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        this.videoRef.current.play();
    }

    render() {
        return (
            <header>
                <video src={HeaderVideo} muted={true} autoPlay={true} loop={true} controls={false} ref={this.videoRef} playsInline={true} />
                <img src={logo} className="logo" />
                <h1 className="title">Augmented Reality</h1>
                <h3 className="sub-title">Mobile Examples</h3>
            </header>
        )
    }
}

export default Header;